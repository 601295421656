import React, { useEffect, useState } from "react";
import { Table, Button, message } from "antd";
import { client } from "../../../../utils/api-client";
import { useAsync } from "../../../../utils/use-async";
import { useAuth } from "../../../../context/auth-context";
function UserAllocation() {
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [tableData, setTableData] = useState([]);
  const fetchUsers = async () => {
    await run(client(`get/allocation/${user.email}`));
  };

  useEffect(() => {
    fetchUsers();
  }, [user]);

  useEffect(() => {
    if (data) {
      setTableData(data?.data);
    }
  }, [data?.data]);
  const handleStart = (record) => {
    console.log("call by neeraj");
    const currentTime = new Date().toISOString();
    const id = record._id;
    const users = user._id;
    if (!user._id) {
      return console.log("user not found");
    }
    console.log(users, "mmm");
    // await addUser({
    //   accountId: record.account_id,
    //   id,
    //   currentTime,
    //   project: record.project_name,
    //   users,
    // });
    const body = {
      accountId: record.account_id,
      id,
      currentTime,
      project: record.project_name,
      users: user._id,
    };
    run(
      client(`timesheet/project/${id}/user/add`, {
        body,
        method: "PUT",
      }).then((res) => {
        if (
          res.message ===
          "This user already has some allocation ID for this date"
        ) {
          message.error(res.message);
          fetchUsers();
        } else {
          message.success(res.message);
          fetchUsers();
        }
      })
    );
  };
  const handleEnd = async (values) => {
    const revokeTime = new Date().toISOString();
    console.log("object", values);
    const body = { values, revokeTime };
    if (user) {
      await run(
        client(`timesheet/${values.project_name}/${user.email}/${values.account_id}/remove`, {
          body,
          method: "PATCH",
        })
      ).then((res) => {
        if (
          res.message === "Revoke time must be greater than allocation time"
        ) {
          message.error(res.message);
          fetchUsers();
        } else {
          message.success(res.message);
          fetchUsers();
        }
      });
    }
  };
  const columns = [
    {
      title: "Allocation ID",
      dataIndex: "account_id",
      key: "account_id",
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleStart(record)}
            disabled={record?.current_user !== ""}
          >
            Start
          </Button>
          <Button
            type="danger"
            onClick={() => handleEnd(record)}
            disabled={
              record.status === "ended" || record.current_user !== user.email
            }
            style={{ marginLeft: 8 }}
          >
            End
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className="container mt-5">
      <h2>User Allocations</h2>
      <Table
        dataSource={tableData}
        columns={columns}
        rowKey="_id"
        loading={isLoading}
      />
      {isError && <p style={{ color: "red" }}>{error.message}</p>}
    </div>
  );
}

export default UserAllocation;
