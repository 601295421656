import React, { useState, useEffect } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Popconfirm, message, Space } from "antd";
// import AddProject from "../add-project";
import { Spinner } from "../../../lib";
import { useHistory } from "react-router-dom"
import AddDepartment from "../add-department";
import { useParams } from "react-router-dom";
import exportFromJSON from 'export-from-json'
import moment from "moment";

const ProjectDepartmnent = () => {
  const {
    run,
    isLoading,
    data
  } = useAsync();
  const {sectionName} = useParams();
  let [all, setAll] = useState([]);
  let [project, setProject] = useState('')
  let [modal, setModal] = useState(false);
  let [allProjects, setAllProjects] = useState([]);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory()
  let [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.message === "Fetched Actual Project Data") {
      setAllProjects(data?.projects)
    }

    if (data?.message === "Fetched Department Successfully") {
      setAll(data?.departmentData?.departments);
      // setProject(data?.departmentData?.name)
      setUsersLoading(false);
    }
  }, [data]);

  const handleDelete = (name) => {
    setToDelete(name);
    run(client(`section/delete/department/${sectionName}`, { method: "DELETE", body: {name}  }));
    fetchSections();
  };

  const columns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <Space>
          <Button type="primary" onClick={() => history.push(`/project/${sectionName}/${record.department_name}`)}>Show Details</Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record?.department_name)}
          >
          <Button type="danger">
            Delete {isLoading && toDelete === record.name ? <Spinner /> : ""}
          </Button>
          </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map(({departments: { department_name }, ...department}, index) => ({ key: index, ...department, department_name, sr: index + 1 }));
  };

  const handlePrint = () => {
    const fileName = `${sectionName}_Data`
    const formattedProjects = allProjects.map(project => {
      project.date = moment(project.author_done_date).format("YYYY-MM-DD");
      
      return project;
    });
    const exportType = "xls"
    exportFromJSON({ data: formattedProjects, fileName, exportType })
  }

  const fetchSections = () => {
    run(client(`section/details/${sectionName}`));
    setUsersLoading(true);
  };

  const fetchAllProjectData = () => {
    run(client(`project/details/all/${sectionName}`))
  }

  useEffect(() => {
    fetchSections();
    fetchAllProjectData();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Add Department for {sectionName}</h1>
        <Button type="primary" onClick={()=>handlePrint()} style={{marginLeft:'auto'}}>
          Download Records
        </Button>
        <Button type="ghost" onClick={showModal} style={{marginLeft:'1rem'}}>
          Add Department
        </Button>
      </div>
      <Table
        loading={usersLoading}
        columns={columns}
        data={all}
        size="middle"
        pagination={{
          pageSize: 100
        }}
      />
      <AddDepartment
        modal={modal}
        handleCancel={handleCancel}
        fetchSections={fetchSections}
        setModal={setModal}
        project = {sectionName}
      />
    </div>
  );
};

export default ProjectDepartmnent;
